<template>
  <VForm
    v-model="formData"
    ref="positionFormRef"
  >
    <div v-if="(isType1 || isType2) && !isCatalog" class="position v-form--grid custom-scroll mb-20">
        <div class="position__item col-1">
            <VSelect
                label="Вид пружины"
                name="shape"
                :options="isType1 ? shapeAll : shapeType2"
                :disabled="isDisabled"
                :required="isRequired"
            >
                <Validation for="required"/>
            </VSelect>
        </div>
    </div>

    <div
        v-if="(!(isType1 || isType2) || formData.shape) || isCatalog"
        class="position v-form--grid custom-scroll"
    >
        <!--1 Столбец-->
        <!--1 строка-->
        <div class="position__item col-1">
        <VInput
            label="d проволоки"
            name="d_wire"
            :width="isType17 ? '55%' : ''"
            :disabled="isDisabled || isDisabledForProcessEngineer"
            :required="isRequired"
            :pattern="pattern.num100"
        >
            <Validation for="required" />
            <Validation for="pattern" key-text="num100"/>
        </VInput>

        <VInput
            v-if="notType4"
            :label="isType127 ? 'Индекс (i) 1' : 'Индекс (i)'"
            name="index"
            width="50%"
            disabled
        />
        <VInput
            v-if="isType127 && isShape"
            label="Индекс (i) 2"
            name="index_2"
            width="50%"
            disabled
        />

        <VInput
            v-if="visibleStiffness"
            label="Жесткость, Н/мм"
            name="stiffness"
            width="50%"
            disabled
        />
        </div>

        <!--2 строка-->
        <div class="position__item col-1" >
        <VInput
            v-if="visibleDInner"
            label="D внутренний"
            name="d_inner"
            :disabled="isDisabled || isDisabledForProcessEngineer"
            :required="isRequired"
            :pattern="pattern.num100"
        >
            <Validation for="required" />
            <Validation for="pattern" key-text="num100"/>
        </VInput>

        <VInput
            v-if="visibleDOutput"
            :label="isType127 && isShape ? 'D1 больший' : 'D наружный'"
            name="d_outer_1"
            :disabled="isDisabled || isDisabledForProcessEngineer"
            :required="isRequired"
            :pattern="pattern.num100"
        >
            <Validation for="required" />
            <Validation for="pattern" key-text="num100"/>
        </VInput>

        <VInput
            v-if="isType127 && isShape"
            label="D1 меньший"
            name="d_outer_2"
            :disabled="isDisabled || isDisabledForProcessEngineer"
            :required="isRequired"
            :pattern="pattern.num100"
        >
            <Validation for="required"/>
            <Validation for="pattern" key-text="num100"/>
        </VInput>

        <VInput
            v-if="isType4"
            label="Габаритная длина изделия"
            name="overall_length"
            :disabled="isDisabled"
            :required="isRequired"
        >
            <Validation for="required"/>
        </VInput>
        </div>

        <!--3 строка-->
        <div class="position__item col-1">
        <VInput
            v-if="notType48"
            label="Длина тела (L0)"
            name="body_length"
            :width="visiblePerpendicularity ? '50%' : null"
            :disabled="isDisabled || isDisabledForProcessEngineer"
            :required="isRequired"
            :pattern="pattern.num100"
        >
            <Validation for="required"/>
            <Validation for="pattern" key-text="num100"/>
        </VInput>

        <InputCopy
            v-if="visiblePerpendicularity"
            label="Перпендикулярность, ⊥"
            name="perpendicularity"
            :model-copy="calcPerpendicularity"
            :disabled="isDisabled"
            :required="isRequired"
        />

        <VInput
            v-if="isType8"
            label="Зазор"
            name="gap"
            :disabled="isDisabled"
            :required="isRequired"
            :pattern="pattern.num100"
        >
            <Validation for="required"/>
            <Validation for="pattern" key-text="num100"/>
        </VInput>
        </div>

        <!--4 строка-->
        <div class="position__item col-1">
        <VSelect
            v-if="isType17"
            label="Опорный виток 1"
            name="reference_turn_1"
            :options="propertiesAll.reference_turn"
            :disabled="isDisabled"
            :required="isRequired"
        >
            <Validation for="required"/>
        </VSelect>

        <VSelect
            v-if="isType17"
            label="Опорный виток 2"
            name="reference_turn_2"
            :options="propertiesAll.reference_turn"
            :disabled="isDisabled"
            :required="isRequired"
        >
            <Validation for="required"/>
        </VSelect>

        <VInput
            v-if="isType359"
            label="Число рабочих витков (n)"
            name="number_turns"
            :disabled="isDisabled || isDisabledForProcessEngineer"
            :required="isRequired"
            :pattern="pattern.num100"
        >
            <Validation for="required" />
            <Validation for="pattern" key-text="num100"/>
        </VInput>

        <VInput
            v-if="isType2"
            label="Длина по зацепам"
            name="length_by_hooks"
            :disabled="isDisabled"
            :required="isRequired"
            :pattern="pattern.num100"
        >
            <Validation for="required" />
            <Validation for="pattern" key-text="num100"/>
        </VInput>

        <VInput
            v-if="isType8"
            label="Число полных витков (n1)"
            name="number_full_turns"
            :disabled="isDisabled || isDisabledForProcessEngineer"
            :required="isRequired"
            :pattern="pattern.num100"
        >
            <Validation for="required"/>
            <Validation for="pattern" key-text="num100"/>
        </VInput>
        </div>

        <!--5 строка-->
        <div class="position__item col-1">
        <VInput
            v-if="isType1"
            label="Число рабочих витков (n)"
            name="number_turns"
            :disabled="isDisabled || isDisabledForProcessEngineer"
            :required="isRequired"
            :pattern="pattern.num100"
        >
            <Validation for="required" />
            <Validation for="pattern" key-text="num100"/>
        </VInput>

        <VInput
            v-if="isType1"
            label="Число полных витков (n1)"
            name="number_full_turns"
            :disabled="isDisabled || isDisabledForProcessEngineer"
            :pattern="pattern.num100"
        >
            <Validation for="pattern" key-text="num100"/>
        </VInput>

        <VInput
            v-if="isType7"
            label="Число витков на цилиндр"
            name="number_turn_cylinder"
            :lengthTooltip="5"
            :disabled="isDisabled"
            :required="isRequired"
            :pattern="pattern.num100"
        >
            <Validation for="required"/>
            <Validation for="pattern" key-text="num100"/>
        </VInput>

        <VInput
            v-if="isType7"
            label="Число витков на конус"
            name="number_turn_cone"
            :disabled="isDisabled"
            :pattern="pattern.num100"
        >
            <Validation for="pattern" key-text="num100"/>
        </VInput>

        <VInput
            v-if="isType359"
            label="Длина отвода 1"
            name="tap_length_1"
            :disabled="isDisabled"
            :required="isRequired && isType35"
        >
            <Validation for="required" v-if="isType35"/>
        </VInput>

        <VSelect
            v-if="isType2"
            label="Тип зацепа 1"
            name="hook_type_1"
            :options="propertiesAll.hook_type"
            :disabled="isDisabled"
            :required="isRequired"
        >
            <Validation for="required"/>
        </VSelect>

        <VSelect
            v-if="isType8"
            label="Плоскостность"
            name="flatness"
            :options="propertiesAll.flatness"
            :disabled="isDisabled"
            :required="isRequired"
        >
            <Validation for="required"/>
        </VSelect>
        </div>

        <!--6 строка-->
        <div class="position__item col-1">
        <VInput
            v-if="isType7"
            label="Число полных витков (n1)"
            name="number_full_turns"
            :disabled="isDisabled || isDisabledForProcessEngineer"
            :required="isRequired"
            :pattern="pattern.num100"
        >
            <Validation for="required" />
            <Validation for="pattern" key-text="num100"/>
        </VInput>

        <InputCopy
            v-if="isType1"
            label="Шаг (t)"
            name="step"
            :model-copy="calcStep"
            :disabled="isDisabled"
            :required="isRequired"
            pattern-key="num100"
        />

        <VInput
            v-if="isType359"
            label="Длина отвода 2"
            name="tap_length_2"
            :disabled="isDisabled"
            :required="isRequired && isType35"
        >
            <Validation for="required" v-if="isType35"/>
        </VInput>

        <VSelect
            v-if="isType2"
            label="Тип зацепа 2"
            name="hook_type_2"
            :options="propertiesAll.hook_type"
            :disabled="isDisabled"
            :required="isRequired"
        >
            <Validation for="required"/>
        </VSelect>

        <VSelect
            v-if="isType8"
            label="Фаска"
            name="chamfer"
            :options="propertiesAll.chamfer"
            :disabled="isDisabled"
            :required="isRequired"
        >
            <Validation for="required"/>
        </VSelect>
        </div>

        <!--7 строка-->
        <div v-if="isType2 || isType5" class="position__item col-1" >
        <VSelect
            v-if="isType2"
            label="Ориентация зацепов"
            name="hook_orientation_1"
            :options="propertiesAll.hook_orientation"
            :disabled="isDisabled"
            :required="isRequired"
        >
            <Validation for="required"/>
        </VSelect>

        <VInput
            v-if="isType5"
            label="Длина зацепа"
            name="hook_length_1"
            :disabled="isDisabled"
        />
        </div>
      <!--8 строка-->
      <div v-if="isType2" class="position__item col-1 row-8" >
      </div>

        <!--8 строка-->
        <div class="position__item col-1 row-9 relative" >
            <VInput
                label="Комментарий для производства"
                type="textarea"
                name="comment"
                :disabled="isDisabled"
                :required="!isTemptate && !isCatalog"
            >
                <Validation for="required"/>
            </VInput>

            <div class="comment-buttons flex gap-xxs">
                <VButton
                    v-if="!isDisabled && !isTemptate && !isCatalog"
                    text="СЗ"
                    icon="create"
                    size="sm"
                    @click.native="$emit('copyRelativeOrders')"
                />

                <VButton
                    v-if="!isDisabled && !isTemptate && !isCatalog"
                    text="ПП"
                    icon="create"
                    size="sm"
                    @click.native="$emit('copyNoteManufacture')"
                />
            </div>
        </div>

        <!--2 Столбец-->
        <!--1 строка-->
        <div class="position__item col-2 row-1">
        <VSelect
            v-if="notType48"
            label="Направление навивки"
            name="winding_dir"
            :options="propertiesAll.winding_direction"
            :disabled="isDisabled"
            :required="isRequired && !isType5"
        >
            <Validation for="required" v-if="!isType5"/>
        </VSelect>

        <VInput
            v-if="isType9"
            label="Ориентация отводов"
            name="tap_orientation"
            :disabled="isDisabled"
            :required="isRequired && isType35"
            :pattern="pattern.num"
        >
            <Validation for="required" v-if="isType35"/>
            <Validation for="pattern" key-text="num"/>
        </VInput>

        <VInput
            v-if="isType35"
            label="Угол м/у отводами и зацепом"
            name="tap_angle"
            :disabled="isDisabled"
            :required="isRequired"
            :pattern="pattern.num"
        >
            <Validation for="required"/>
            <Validation for="pattern" key-text="num"/>
        </VInput>
        </div>

        <!--2 строка-->
        <div class="position__item col-2 row-2">
        <InputCopy
            v-if="visibleDOutput"
            :label="isType127 && isShape? 'Допуск по D1 большему' : 'Допуск по D наруж.'"
            name="d_outer_1_limit"
            :model-copy="calcDiameterLimit"
            :disabled="isDisabled"
            :required="isRequired"
            pattern-key="limitNum100"
        />

        <InputCopy
            v-if="isType127 && isShape"
            label="Допуск по D1 меньшему"
            name="d_outer_2_limit"
            :model-copy="calcDOuterLimit2"
            :disabled="isDisabled"
            :required="isRequired"
            pattern-key="limitNum100"
        />

        <InputCopy
            v-if="visibleDInner"
            label="Допуск по D внутр."
            name="d_inner_limit"
            :model-copy="calcDiameterLimit"
            :disabled="isDisabled"
            :required="isRequired"
            pattern-key="limitNum100"
        />

        <VInput
            v-if="isType4"
            label="Допуск на линейные размеры"
            name="linear_size_limit"
            :disabled="isDisabled"
            :pattern="pattern.limitNum100"
        >
            <Validation for="pattern" key-text="limitNum100"/>
        </VInput>
        </div>

        <!--3 строка-->
        <div class="position__item col-2 row-3">
          <InputCopy
            v-if="isType8"
            label="Допуск на зазор"
            name="gap_limit"
            :model-copy="calcClearanceTolerance"
            :disabled="isDisabled"
            :required="isRequired"
            pattern-key="limitNum100"
          />
          <VInput
            v-if="isType2"
            label="Число рабочих витков (n)"
            name="number_turns"
            :disabled="isDisabled || isDisabledForProcessEngineer"
            :required="isRequired"
            :pattern="pattern.num100"
          >
            <Validation for="required"/>
            <Validation for="pattern" key-text="num100"/>
          </VInput>
          <!--    Для стопорных колец выводим на следующей строке так как на этой строке уже выводится допуск на зазор     -->
          <InputCopy
            v-if="visibleTurnsLimit && !isType8"
            label="Допуск по виткам"
            name="turns_limit"
            :model-copy="calcTurnsLimit"
            :disabled="isDisabled"
            :required="isRequired"
          />
          <VSelect
            v-if="isType35"
            label="Тип навивки"
            name="winding_type"
            :options="propertiesAll.winding_type"
            :required="isRequired"
            :disabled="isDisabled"
          >
            <Validation for="required"/>
          </VSelect>
        </div>

        <!--4 строка-->
        <div class="position__item col-2 row-4">
          <InputCopy
            v-if="notType48"
            label="Допуск по длине"
            name="body_length_limit"
            :model-copy="calcBodyLengthLimit"
            :disabled="isDisabled"
            :required="isRequired"
            :pattern-key="!this.isType35 ? 'limitNum100' : ''"
          />

          <!--   Вывод на этой строке только для стопорных колец       -->
          <InputCopy
            v-if="isType8"
            label="Допуск по виткам"
            name="turns_limit"
            :model-copy="calcTurnsLimit"
            :disabled="isDisabled"
            :required="isRequired"
          />
        </div>

        <!--5 строка-->
        <div class="position__item col-2 row-5" >
          <VSelect
            v-if="isType8"
            label="Загибы"
            name="folds"
            :options="propertiesAll.fold"
            :disabled="isDisabled"
          >
            <Validation for="required"/>
          </VSelect>
        <InputCopy
            v-if="isType359"
            label="Допуск на длину отвода 1"
            name="tap_length_1_limit"
            :model-copy="calcTapLengthLimit1"
            :disabled="isDisabled"
            :required="isRequired && isType35"
            :pattern-key="!this.isType35 ? 'limitNum100' : ''"
        />

        <VInput
            v-if="isType2"
            label="Длина зацепа 1"
            name="hook_length_1"
            :required="isRequired"
            :disabled="isDisabled"
        >
            <Validation for="required"/>
        </VInput>
        </div>

        <!--6 строка-->
        <div class="position__item col-2 row-6">
        <InputCopy
            v-if="isType359"
            label="Допуск на длину отвода 2"
            name="tap_length_2_limit"
            :model-copy="calcTapLengthLimit2"
            :disabled="isDisabled"
            :required="isRequired && isType35"
            :pattern-key="!this.isType35 ? 'limitNum100' : ''"
        />

        <VInput
            v-if="isType2"
            label="Длина зацепа 2"
            name="hook_length_2"
            :required="isRequired"
            :disabled="isDisabled"
        >
            <Validation for="required"/>
        </VInput>

        <InputCopy
            v-if="isType7"
            label="Шаг (t)"
            name="step"
            :model-copy="calcStep"
            :disabled="isDisabled"
            :required="isRequired"
            pattern-key="num100"
        />

        <InputCopy
            v-if="isType17"
            label="Допуск на шаг"
            name="step_limit"
            :model-copy="calcStepLimit"
            :disabled="isDisabled"
            :required="isRequired"
        />
        </div>

        <!--7 строка-->
        <div v-if="isType2 || isType7" class="position__item col-2 row-7" >
        <VInput
            v-if="isType2"
            label="Зазор зацепа 1"
            name="hook_gap_1"
            :disabled="isDisabled"
            :required="isRequired"
            :pattern="pattern.num100"
        >
            <Validation for="required"/>
            <Validation for="pattern" key-text="num100"/>
        </VInput>

        <InputCopy
          v-if="isType2"
          label="Допуск на зазор 1"
          name="hook_gap_1_limit"
          :model-copy="calcClearanceTolerance2"
          :disabled="isDisabled"
          :required="isRequired"
          pattern-key="limitNum100"
        />

        <VInput
            v-if="isType5"
            label="Угол загиба на зацепе"
            name="hook_bend_angle"
            :disabled="isDisabled"
        />
        </div>
      <!--8 строка-->
        <div  v-if="isType2" class="position__item col-2 row-8">
          <VInput
            v-if="isType2"
            label="Зазор зацепа 2"
            name="hook_gap_2"
            :disabled="isDisabled"
            :required="isRequired"
            :pattern="pattern.num100"
          >
            <Validation for="required"/>
            <Validation for="pattern" key-text="num100"/>
          </VInput>

          <InputCopy
            v-if="isType2"
            label="Допуск на зазор 2"
            name="hook_gap_2_limit"
            :model-copy="calcClearanceTolerance2"
            :disabled="isDisabled"
            :required="isRequired"
            pattern-key="limitNum100"
          />
        </div>

        <!--9 строка-->
        <div class="position__item col-2 row-9 relative" >
            <VInput
                label="Комментарий для ОТК"
                type="textarea"
                name="note_otk"
                :disabled="isDisabled"
                :required="!isTemptate && !isCatalog"
                >
                <Validation for="required"/>
            </VInput>

            <div class="comment-buttons flex gap-xxs">
                <VButton
                    v-if="!isDisabled && !isTemptate && !isCatalog"
                    text="ПО"
                    icon="create"
                    size="sm"
                    @click.native="$emit('copyNoteOTK')"
                />
            </div>
        </div>

        <!--3 Столбец-->
        <!--1 строка-->
        <div class="position__item col-3 row-1">
        <VSelect
            label="Материал"
            name="material"
            :options="propertiesAll.material"
            :disabled="isDisabled || isDisabledForProcessEngineer"
            :required="isRequired"
        >
            <Validation for="required"/>
        </VSelect>
        </div>

        <!--2 строка-->
        <div class="position__item col-3 row-2">
        <InputCopy
            label="Длина развертки"
            name="sweep_length"
            :model-copy="calcSweepLength"
            :disabled="isDisabled"
            :required="isRequired"
            pattern-key="num100"
        />

        <VInput
            label="Масса, кг"
            v-model="calcWeight"
            width="50%"
            disabled
        >
            <Validation for="pattern" key-text="num100"/>
            <Validation for="required"/>
        </VInput>
        </div>

        <!--3 строка-->
        <div class="position__item col-3 row-3">
          <InputCopy
            label="Твердость"
            name="hardness"
            :model-copy="calcHardness"
            :disabled="isDisabled"
            :required="isRequired && !isType9"
          />
        </div>

        <!--4 строка-->
        <div class="position__item col-3 row-4">
          <SelectTooltip
            label="Поверхностная обработка"
            name="surface_treatment"
            :options="propertiesAll.surface_treatment"
            :model-tooltip="calcSurfaceTreatment"
            :disabled="isDisabled"
            :required="isRequired"
          />
        </div>

      <!--5 строка-->
        <div class="position__item col-3 row-5">
        <VSelect
            label="Испытания"
            name="tests"
            :options="propertiesAll.test"
            :disabled="isDisabled"
            :required="isRequired"
        >
            <Validation for="required"/>
        </VSelect>

        <InputCopy
            v-if="hasProbes"
            label="Количество пробников"
            name="probes"
            :model-copy="calcProbes"
            :disabled="isDisabled"
            :required="isRequired"
            pattern-key="num"
        />
        </div>

        <!--6 строка-->
        <div class="position__item col-3 row-6">
        <VSelect
            label="Покрытие"
            name="coating"
            :options="coatingOptionsFiltered"
            :disabled="isDisabled"
            :required="isRequired"
        >
            <Validation for="required"/>
        </VSelect>

        <VInput
            v-if="isDisabled && formData.color_text && !formData.color"
            label="Цвет"
            name="color_text"
            disabled
        />

        <VSelect
            v-else
            label="Цвет"
            name="color"
            :options="colorOptionsFiltered"
            searchable
            :disabled="isDisabled"
            :required="isRequired"
        >
            <Validation for="required"/>
        </VSelect>
        </div>
    </div>
  </VForm>
</template>

<script>
import VForm from '@/components/Form/VForm'
import VSelect from '@/components/Form/VSelect'
import VInput from '@/components/Form/Vinput/VInput'
import Validation from '@/components/Form/Validation'
import InputCopy from '@/components/Form/Vinput/InputCopy'
import VButton from '@/components/simple/button/VButton'
import SelectTooltip from '@/components/Form/SelectTooltip'

import { mixinFormData } from '@/mixins/form/mixinFormData'
import { mixinPositionType } from '@/mixins/views/Production/Position/mixinPositionType'
import { mixinPositionCalc } from '@/mixins/views/Production/Position/mixinPositionCalc'

import { PATTERN_LIST } from '@/const/validation'
import Vue from 'vue'

import { mapGetters } from 'vuex'
import { POSITION_FORMULAS } from '@/utils/production/position/formula'
import { mixinCoatingAndColorOptionsFiltered } from '@/mixins/views/mixinCoatingAndColorOptionsFiltered'

export default {
  name: 'PositionFormDetails',
  components: {
    VForm,
    InputCopy,
    Validation,
    VInput,
    VSelect,
    VButton,
    SelectTooltip
  },
  mixins: [
    mixinFormData,
    mixinPositionType,
    mixinPositionCalc,
    mixinCoatingAndColorOptionsFiltered
  ],
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    hasProbes: {
      type: Boolean,
      default: false
    },
    isCatalog: {
      type: Boolean,
      default: false
    },
    isTemptate: {
      type: Boolean,
      default: false
    },
    shapeCatalog: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    pattern: PATTERN_LIST
  }),
  computed: {
    ...mapGetters('records', {
      propertiesAll: 'propertiesAll',
      shapeAll: 'shapeAll',
      shapeType2: 'shapeType2'
    }),

    // Используется в mixinCoatingAndColorOptionsFiltered
    currentCoating () { return this.formData.coating },
    currentColor () { return this.formData.color },

    // для calcStiffness
    position () {
      return this.formData
    },
    // Индексы
    calcIndex1 () {
      if (this.visibleDOutput) {
        return POSITION_FORMULAS.indexByD(this.formData.d_wire, this.formData.d_outer_1)
      }
      if (this.visibleDInner) {
        return POSITION_FORMULAS.indexByD(this.formData.d_wire, this.formData.d_inner, true)
      }
      return ''
    },
    calcIndex2 () {
      if (!this.isType127) return ''
      return POSITION_FORMULAS.indexByD(this.formData.d_wire, this.formData.d_outer_2)
    },
    // Допуск по наружному/внутреннему диаметру
    calcDiameterLimit () {
      if (this.isType4) return ''
      return POSITION_FORMULAS.diameterLimit(this.formData.d_wire, this.calcIndex1)
    },
    // Допуск по наружному диаметру 2
    calcDOuterLimit2 () {
      if (!this.isType127) return ''
      return POSITION_FORMULAS.diameterLimit(this.formData.d_wire, this.calcIndex2)
    },
    // Допуск по длине тела
    calcBodyLengthLimit () {
      if (!this.notType48) return ''
      if (this.isType2) {
        return POSITION_FORMULAS.bodyLengthLimitForType2({
          dWire: this.formData.d_wire,
          turns: this.formData.number_turns
        })
      }
      if (this.isType359) {
        return POSITION_FORMULAS.bodyLengthLimitByLength(this.formData.body_length)
      }
      return POSITION_FORMULAS.bodyLengthLimit({
        dWire: this.formData.d_wire,
        turns: this.formData.number_turns,
        step: this.formData.step
      })
    },
    // Масса 1 пружины
    calcWeight () {
      return POSITION_FORMULAS.weight({
        dWire: this.formData.d_wire,
        sweepLength: this.formData.sweep_length,
        density: this.formData?.material?.density
      })
    },
    // Длина развертки
    calcSweepLength () {
      if (this.isType2) {
        return POSITION_FORMULAS.sweepLengthByHookType({
          dWire: this.formData.d_wire,
          dOuter: this.formData.d_outer_1,
          turns: this.formData.number_turns,
          ht1Id: this.formData?.hook_type_1?.id,
          ht2Id: this.formData?.hook_type_2?.id,
          hl1: this.formData?.hook_length_1,
          hl2: this.formData?.hook_length_2,
          hg1: this.formData?.hook_gap_1,
          hg2: this.formData?.hook_gap_2
        })
      }
      if (this.isType3) {
        return POSITION_FORMULAS.sweepLengthByTapLengthAndDOuter({
          dWire: this.formData.d_wire,
          dOuter: this.formData.d_outer_1,
          turns: this.formData.number_turns,
          tapLength1: this.formData.tap_length_1,
          tapLength2: this.formData.tap_length_2
        })
      }
      if (this.isType5) {
        return POSITION_FORMULAS.sweepLengthByTapLengthAndDInner({
          dWire: this.formData.d_wire,
          dInner: this.formData.d_inner,
          turns: this.formData.number_turns,
          tapLength1: this.formData.tap_length_1,
          tapLength2: this.formData.tap_length_2
        })
      }
      if (this.isType9) {
        return POSITION_FORMULAS.sweepLengthByD({
          dWire: this.formData.d_wire,
          diameter: this.formData.d_inner,
          turns: this.formData.number_turns,
          isInner: true
        })
      }
      if (this.isType17) {
        return POSITION_FORMULAS.sweepLengthByD({
          dWire: this.formData.d_wire,
          diameter: this.formData.d_outer_1,
          turns: this.formData.number_full_turns
        })
      }
      return ''
    },
    // Шаг
    calcStep () {
      return POSITION_FORMULAS.step({
        dWire: this.formData?.d_wire,
        rt1Id: this.formData?.reference_turn_1?.id,
        rt2Id: this.formData?.reference_turn_2?.id,
        turns: this.formData.number_turns,
        bodyLength: this.formData.body_length
      })
    },
    // Пробники
    calcProbes () {
      return POSITION_FORMULAS.probes(this.formData?.d_wire, this.formData?.value)
    },
    // Перпендикулярность
    calcPerpendicularity () {
      return POSITION_FORMULAS.perpendicularity(this.formData?.body_length)
    },
    // Допуск на шаг
    calcStepLimit () {
      return POSITION_FORMULAS.stepLimit(this.formData?.step, this.formData?.d_wire)
    },
    // Допуск по виткам
    calcTurnsLimit () {
      if (this.isType1) {
        return POSITION_FORMULAS.turnsLimitType1({
          dWire: this.formData?.d_wire,
          turns: this.formData?.number_full_turns
        })
      }
      return POSITION_FORMULAS.turnsLimit(this.turnsForCalc, this.formData?.d_wire)
    },
    turnsForCalc () {
      return this.isType2 || this.isType9 ? this.formData?.number_turns : this.formData?.number_full_turns
    },
    // Допуск на длину отвода
    calcTapLengthLimit1 () {
      if (!this.isType359) return ''
      return POSITION_FORMULAS.tapLengthLimit(this.formData.tap_length_1)
    },
    calcTapLengthLimit2 () {
      if (!this.isType359) return ''
      return POSITION_FORMULAS.tapLengthLimit(this.formData.tap_length_2)
    },
    // Твердость
    calcHardness () {
      if (!this.formData?.material?.hardness) {
        return ''
      }
      return this.formData.material.hardness
    },
    // Поверхностная обработка
    calcSurfaceTreatment () {
      // console.log(this.formData.material?.gost_standard_id)
      if (!this.formData?.material?.id) {
        return null
      }
      const gost = this.$store.getters['orderDetail/getGostByMaterialId'](this.formData.material.id)
      if (gost[0]?.surface_treatment?.id) {
        return this.$store.getters['records/getSurfaceTreatment'](gost[0].surface_treatment.id)
      }

      return null
    },

    // Допуск на зазор (Стопорные кольца)
    calcClearanceTolerance () {
      return POSITION_FORMULAS.clearanceTolerance(this.formData?.d_outer_1_limit)
    },

    // Допуск на зазор (Пружины растяжения)
    calcClearanceTolerance2 () {
      return POSITION_FORMULAS.clearanceTolerance2(this.formData?.d_wire)
    },

    isDisabledForProcessEngineer () {
      return this.isCatalog && this.$store.getters['permission/isProcessEngineer']
    },

    isShape () {
        if (this.isCatalog) {
            if (this.shapeCatalog?.id === 1) return false
            else return true
        } else {
            if (this.formData.shape?.id === 1) return false
            else return true
        }
    }
  },
  watch: {
    calcIndex1: {
      handler (val) {
        Vue.set(this.formData, 'index', val)
        if (!this.formData.shape && this.isType2) this.formData.shape = this.shapeAll[0]
      },
      immediate: true
    },
    calcIndex2: {
      handler (val) {
        Vue.set(this.formData, 'index_2', val)
      },
      immediate: true
    },
    calcStiffness: {
      handler (val) {
        Vue.set(this.formData, 'stiffness', val)
      },
      immediate: true
    },
    calcWeight: {
      handler (val) {
        Vue.set(this.formData, 'weight', val)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.comment-buttons {
  position: absolute;
  top: -3px;
  right: 0;
}
</style>
