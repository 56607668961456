import { mapState } from 'vuex'

// Необходимо определить в компоненте currentCoating и currentColor
export const mixinCoatingAndColorOptionsFiltered = {
  computed: {
    ...mapState('records', {
      properties: 'properties'
    }),

    colorOptionsFiltered () {
      const coatingId = this.currentCoating?.id

      if (!coatingId) {
        return this.properties.color
      }

      return this.properties.color.filter(color => color.coating_id === coatingId)
    },

    coatingOptionsFiltered () {
      if (!this.currentColor) {
        return this.properties.coating
      }

      return this.properties.coating.filter(coating => coating.id === this.currentColor.coating_id)
    },

    currentCoating () { return null },
    currentColor () { return null }
  }
}
