<template>
  <div class="input-files">
    <h3 class="input-files__label fw-normal">{{ labelText }}</h3>

    <p class="mb-20 text-grey">
      Всего файлов: {{ countFiles }}
    </p>

    <div v-if="isEdit" class="mb-16">
      <input
        ref="inputFiles"
        type="file"
        :name="name"
        hidden
        multiple
        @change="uploadFilesHandler"
      />

      <VButton
        text="Выберите файл"
        size="xxl"
        @click.native="$refs.inputFiles.click()"
      />

      <small class="text-grey">
        Формат файла: pdf, jpeg, png, webp, gif, csv, plain, MSWord
      </small>
    </div>

    <AttachmentFilesList
      v-if="modelValue"
      class="input-files__list"
      :files="modelValue"
      :is-enabled-remove-file="isEdit"
      :with-preview="withPreview"
      @onRemoveFile="removeFileHandler"
    />
  </div>
</template>

<script>
import AttachmentFilesList from '@/components/files/AttachmentFiles/AttachmentFilesList'
import VButton from '@/components/simple/button/VButton'
import { mixinInputFiles } from '@/mixins/form/mixinInputFiles'

export default {
  name: 'VInputFiles',
  components: {
    AttachmentFilesList,
    VButton
  },
  mixins: [mixinInputFiles],
  props: {
    withPreview: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    labelText () {
      return this.label || (this.isEdit ? 'Прикрепите файлы' : 'Файлы')
    }
  }
}
</script>
